import { areIntervalsOverlapping, addDays, /*subDays,*/ format, eachDayOfInterval, parse } from 'date-fns';
import {
  setSelectedZoneData,
  setClickedAppointmentId,
  setDraggingElement,
  setIsCopying,
  setBlockers,
  setDateRange,
  setTableWidth,
  setStaffers,
  setPatients,
  setInitialCabinets,
  setSelectedDate,
} from '../reducer';
import getAttentionIcon from '../../../components/Icons/getAttentionIcon';
import { toast } from 'react-hot-toast';
import store from '../../../store';
import { weekdaysKeys } from './settings';
import { TFilterBackendAppointments } from './types';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { mapScheduleDataToSessions } from '../../../features/staff/components/StaffScheduleSetup/functions';
import axios from 'axios';
import requests, { baseURL } from '../../../utils/request';
import { filterWorkdays, getEndTime, getStartTime, haveIntersection, isWorkday } from './utils';

const MS_PER_MINUTE = 60000;

// Преобразует данные о визитах с бека в читабельный для таблицы формат
export const mapAppointmentsDataToAppointments = (
  appointments,
  statuses,
  doctors,
  initialCabinets,
  patients,
  staffers,
) => {
  const statusesIDs = statuses.map((status) => status.id);
  const doctorsIDs = doctors.map((status) => status.id);
  const cabinetsIDs = initialCabinets.map((cabinet) => cabinet.id);

  const patientLookup = {};
  patients.forEach((patient) => {
    patientLookup[patient.id] = patient;
  });

  const staffersLookup = {};
  staffers.forEach((staffer) => {
    staffersLookup[staffer.id] = staffer;
  });

  // Если у визита есть причина отмены - его не нужно показывать в таблице
  const filteredAppointments = appointments
    .filter((appointment) => !appointment.cancel_reason)
    .filter((appointment) => cabinetsIDs.includes(appointment.cabinet));

  return filteredAppointments.map((appointment) => {
    const thisAppointmentPatient = patientLookup[appointment.patient];
    const thisAppointmentDoctor = staffersLookup[appointment.doctor];

    return {
      // Обязательные данные для отображения в таблице
      id: appointment.id,
      resourceId: appointment.cabinet,
      title: `${thisAppointmentPatient.last_name} ${
        thisAppointmentPatient.first_name[0]
      }. ${thisAppointmentPatient.second_name?.[0]?.concat('.')}`,
      start: appointment.starts_at,
      end: appointment.ends_at,

      // Другие данные, которые будут использоваться
      cancel_reason: appointment.cancel_reason,
      comment: appointment.comment,
      created_at: appointment.created_at,
      description: appointment.description,
      doctor: thisAppointmentDoctor,
      patient: thisAppointmentPatient,
      status: appointment.status,

      // Флаг того что визит переносят/растягивают
      isDraggingOrResizing: false,
      // Флаг для присвоение стилей для состояния "визит готов к перемещению"
      isReadyToDrag: false,
      // Флаг для применения к визиту display: none; когда это нужно
      isHidden: false,
      // Флаг для обозначения неактуального (согласно фильтру) визита
      isIrrelevant:
        (statusesIDs.length ? !statusesIDs.includes(appointment.status) : false) ||
        (doctorsIDs.length ? !doctorsIDs.includes(appointment.doctor) : false),
      currentRedactor: '',
    };
  });
};

// Берёт из табличного визита данные для работы с модалкой VisitCard
export const getTableAppointmentData = (appointment) => {
  return appointment
    ? {
        id: ~~appointment._def.publicId,
        clinic: appointment._def.extendedProps.doctor.clinic,
        cabinet: appointment._def.resourceIds[0],
        starts_at: appointment._instance.range.start,
        ends_at: appointment._instance.range.end,
        doctor: appointment._def.extendedProps.doctor,
        patient: appointment._def.extendedProps.patient,
        status: appointment._def.extendedProps.status,
        comment: appointment._def.extendedProps.comment,
        cancel_reason: appointment._def.extendedProps.cancel_reason,
      }
    : null;
};

// Функция для проверки визита при перемещении: его можно поместить только поверх рабочей сессии (у неё свойство background)
// Если поместить визит поверх другого визита, функция вернёт false и такого перемещения не произойдёт
export const eventOverlap = (stillEvent) => {
  // toast.error(`На это время уже есть запись в кабинете ${stillEvent.getResources()[0].title}`);
  return stillEvent._def.ui.display === 'background';
};

// Функция для проверки выделяемой области: можно выбирать области с рабочими сессиями, но нельзя выбирать области с визитами
// export const selectOverlap = (event) => {
//   toast(`На это время уже есть запись в кабинете ${event.getResources()[0].title}`, {
//     icon: <ErrorIcon />,
//   });
//   return event._def.ui.display === 'background';
// };

// Получение массива рабочих сессий/визитов, с разными фильтрами
export const getFilteredEvents = (
  events,
  mode,
  eventID = undefined,
  startDate = undefined,
  endDate = undefined,
  cabinetID = undefined,
) => {
  return events.filter((event) => {
    let check;

    // Проверка в зависимости от переданного параметра mode: является ли текущий item сессией, или визитом?
    if (mode === 'sessions') {
      check = event._def.ui.display === 'background';
      if (!check) {
        return check;
      }
    } else if (mode === 'appointments') {
      check = event._def.ui.display === null;
      if (!check) {
        return check;
      }
    } else if (mode === 'block') {
      check = event._def.ui.display === 'block';
      if (!check) {
        return check;
      }
    } else if (mode === 'all') {
      check = true;
    }
    // Проверка: совпадает ли переданный фильтр по ID с ID текущей сессии/визита?
    if (eventID) {
      check = event._def.publicId == eventID;
    }
    // Проверка: диапазон текущей сессии/визита имеет общее время с заданными фильтрами?
    if (startDate && endDate) {
      const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
      check =
        check &&
        areIntervalsOverlapping(
          { start: startDate, end: endDate },
          {
            start: event._instance.range.start.getTime() + timeZoneOffset,
            end: event._instance.range.end.getTime() + timeZoneOffset,
          },
        );
      if (!check) {
        return check;
      }
    }
    // Проверка: равны ли ID кабинета текущей сессии/визита и ID кабинета в фильтре?
    if (cabinetID) {
      check = check && event._def.resourceIds[0] === cabinetID;
      if (!check) {
        return check;
      }
    }
    // Если все проверки пройдены, возвращается результат проверки
    return check;
  });
};

// Функция для обновления визита
export const updateTableAppointment = (
  id,
  cabinet,
  starts_at,
  ends_at,
  cancel_reason,
  comment,
  doctor,
  status,
  schedulerAPI,
) => {
  const targetAppointment = getFilteredEvents(schedulerAPI.getEvents(), 'appointments', id)[0];
  if (targetAppointment) {
    schedulerAPI.batchRendering(function () {
      cabinet && targetAppointment.setResources([cabinet]);
      starts_at && targetAppointment.setStart(starts_at);
      ends_at && targetAppointment.setEnd(ends_at);
      cancel_reason && targetAppointment.setExtendedProp('cancel_reason', cancel_reason);
      comment && targetAppointment.setExtendedProp('comment', comment);
      doctor && targetAppointment.setExtendedProp('doctor', doctor);
      status && targetAppointment.setExtendedProp('status', status);
      targetAppointment._def.extendedProps.isHidden && targetAppointment.setExtendedProp('isHidden', false);
    });
  }
};

// Функция, которая проверяет: случилось ли действие с визитом в прошлом?
// Если так случилось, нужно выдать предупреждение "Выбрано время в прошлом"
export const checkForActionInPast = (actionDate) => {
  if (actionDate < new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000) {
    toast('Выбрано время в прошлом', {
      icon: getAttentionIcon(),
    });
  }
};

// Функция, которая проверяет: нет ли на совпадающее время визитов в других кабинетах?
// Если так случилось, нужно выдать предупреждение "У данного врача есть запись на это время в кабинете *кабинет*"
export const checkForTimeMatches = (appointmentIdToCheck, schedulerAPI) => {
  const appointmentToCheck = schedulerAPI
    .getEvents()
    .filter((appointment) => !appointment._def.ui.display)
    .find((appointment) => ~~appointment._def.publicId === appointmentIdToCheck);

  const matchingAppointments = schedulerAPI
    .getEvents()
    .filter((appointment) => !appointment._def.ui.display)
    .filter((appointment) => ~~appointment._def.publicId !== appointmentIdToCheck)
    .filter(
      (appointment) => appointment._def.extendedProps.doctor.id === appointmentToCheck._def.extendedProps.doctor.id,
    )
    .filter(
      (appointment) =>
        new Date(appointment._instance.range.start).setHours(0, 0, 0, 0) ===
        new Date(appointmentToCheck._instance.range.start).setHours(0, 0, 0, 0),
    )
    .filter((appointment) =>
      areIntervalsOverlapping(
        {
          start: appointmentToCheck._instance.range.start.getTime(),
          end: appointmentToCheck._instance.range.end.getTime(),
        },
        {
          start: appointment._instance.range.start.getTime(),
          end: appointment._instance.range.end.getTime(),
        },
      ),
    );

  if (matchingAppointments.length) {
    let cabinetsStringsArray = [];
    matchingAppointments.forEach(
      (appointment) => (cabinetsStringsArray = [...cabinetsStringsArray, appointment.getResources()[0].title]),
    );
    toast(
      `У данного врача есть запись на это время в кабинет${matchingAppointments.length > 1 ? 'aх' : 'е'} ${[
        ...new Set(cabinetsStringsArray),
      ].join(', ')}`,
      {
        icon: getAttentionIcon(),
      },
    );
  }
};

// Функция, вызывающаяся при выделении участка таблицы
export const select = async (
  args,
  schedulerAPI,
  createAppointment,
  updateAppointment,
  handleStartEdit,
  handleStopEdit,
  dispatch,
) => {
  const { draggingElement, isCopying } = store.getState().reworkedSchedule;
  // Если при клике на ячейку мы тащим визит
  if (draggingElement) {
    const { id, doctor, status, starts_at, ends_at, patient, comment, isIrrelevant } = draggingElement;
    const newEndTime = args.start.getTime() + (new Date(ends_at).getTime() - new Date(starts_at).getTime());
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
    // Проверяем, не мешает ли визиту ничего "встать на новое место"
    const blockingEvents = schedulerAPI
      .getEvents()
      .filter((event) => !event._def.ui.display)
      .filter((event) => event._def.resourceIds[0] === args.resource._resource.id)
      .filter((event) => event._instance.range.start.getMonth() === new Date(starts_at).getMonth())
      .filter((event) => event._instance.range.start.getDate() === new Date(starts_at).getDate())
      .filter((event) => !event._def.extendedProps.isHidden)
      .filter((event) =>
        areIntervalsOverlapping(
          {
            start: event._instance.range.start.getTime() + timeZoneOffset,
            end: event._instance.range.end.getTime() + timeZoneOffset,
          },
          { start: args.start.getTime(), end: newEndTime },
        ),
      );
    // Если ничего не мешает, будет 2 кейса: для копирования, и для перемещения
    if (!blockingEvents.length) {
      if (!isCopying) {
        // Если происходит перемещение - обновить существующий визит, и на беке, и в таблице
        updateAppointment({
          id,
          clinic: doctor.clinic,
          cabinet: parseInt(args.resource._resource.id),
          starts_at: args.start,
          ends_at: new Date(newEndTime),
        })
          .unwrap()
          .then(() => {
            // Проверить и дать предупреждения в случае если есть визиты на совпадающее время в других кабинетах
            checkForTimeMatches(id, schedulerAPI);
            updateTableAppointment(
              id,
              parseInt(args.resource._resource.id),
              args.start,
              new Date(newEndTime),
              null,
              null,
              null,
              null,
              schedulerAPI,
            );
          })
          .catch(() => {
            toast.error('Произошла техническая ошибка, попробуйте переместить визит позже');
          });
      } else {
        // Если происходит копирование - создать новый визит, и на беке, и в таблице
        createAppointment({
          id: doctor.clinic,
          data: {
            doctor: doctor.id,
            status,
            cabinet: parseInt(args.resource._resource.id),
            starts_at: args.start,
            ends_at: new Date(args.start.getTime() + (new Date(ends_at).getTime() - new Date(starts_at).getTime())),
            clinic: doctor.clinic,
            patient: patient.id,
            comment,
          },
        })
          .unwrap()
          .then((payload) => {
            schedulerAPI.addEvent({
              id: payload.id,
              publicId: payload.id,
              resourceId: parseInt(args.resource._resource.id),
              start: args.start,
              end: new Date(args.start.getTime() + (new Date(ends_at).getTime() - new Date(starts_at).getTime())),
              doctor,
              status,
              cabinet: parseInt(args.resource._resource.id),
              patient,
              comment,
              isCopied: true,
              isIrrelevant,
            });
            // Проверить и дать ошибки в случае если есть визиты на совпадающее время в других кабинетах
            checkForTimeMatches(payload.id, schedulerAPI);
            // Создаю и заканчиваю сессию для нового визита, чтобы дать фронту сигнал на обновление
            handleStartEdit(payload.id);
            handleStopEdit(payload.id);
          })
          .catch((error) => {
            toast.error('Произошла техническая ошибка, попробуйте скопировать визит позже');
            console.error(error);
          })
          .finally(() => handleStopEdit(id));
      }
      // Проверить и дать ошибки в случае если действие сделано в прошлом
      checkForActionInPast(args.start.getTime() - timeZoneOffset);
      // Если что-то мешает, выдать ошибку "На это время уже есть запись в кабинете *кабинет*"
    } else {
      toast.error(`На это время уже есть запись в кабинете ${args.resource._resource.title}`);
    }
    const draggedElement = getFilteredEvents(schedulerAPI.getEvents(), 'appointments', id)[0];
    if (draggedElement) {
      draggedElement.setExtendedProp('currentRedactor', '');
      !isCopying && draggedElement.setExtendedProp('isHidden', false);
    }
    schedulerAPI.unselect();
    dispatch(setDraggingElement(null));
    dispatch(setIsCopying(false));
    document.getElementById('draggingAppointment').remove();
    handleStopEdit(id);
    // Прекращаю сессию элемента, который копировали или перемещали.
  } else {
    // Если перетаскиваемых элементов нет - передаём в redux данные о выбранной области таблицы
    // Наличие таких данных откроет модалку AppointmentForm
    const selectedEvents = getFilteredEvents(
      schedulerAPI.getEvents(),
      'all',
      undefined,
      args.start,
      args.end,
      args.resource._resource.id,
    );

    const selectedAppointments = selectedEvents.filter((event) => event._def.ui.display === null);
    const selectedSessions = selectedEvents.filter((event) => event._def.ui.display === 'background');

    if (!selectedAppointments.length) {
      const payload = {
        selectedZoneDoctorId: selectedSessions.length > 0 ? selectedSessions[0]._def.extendedProps.stafferId : null,
        selectedZoneCabinet: args.resource._resource,
        selectedZoneTime: {
          start: args.start.getTime(),
          end: args.end.getTime(),
        },
      };

      dispatch(setSelectedZoneData(payload));
    } else {
      if (!selectedAppointments.some((appointment) => appointment._def.extendedProps.currentRedactor)) {
        toast.error(`На это время уже есть запись в кабинете ${selectedAppointments[0].getResources()[0].title}`);
      }
      schedulerAPI.unselect();
    }
  }
};

// Функция, вызывающаяся при клике на визит или рабочую сессию
export const eventClick = (args, handleStartEdit, dispatch) => {
  const { draggingElement } = store.getState().reworkedSchedule;
  if (draggingElement) {
    dispatch(setDraggingElement(null));
    toast.error(`На это время уже есть запись в кабинете ${args.event.getResources()[0].title}`);
  } else {
    dispatch(setClickedAppointmentId(~~args.event._def.publicId));
    handleStartEdit(~~args.event._def.publicId);
  }
};

// Функция, вызывающаяся при отпускании визита после перетаскивания (В КОРРЕКТНУЮ ОБЛАСТЬ)
export const eventDrop = async (args, updateAppointment, schedulerAPI) => {
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  updateAppointment({
    id: ~~args.event._def.publicId,
    clinic: args.event._def.extendedProps.doctor.clinic,
    cabinet: args.event._def.resourceIds[0],
    starts_at: new Date(args.event._instance.range.start.getTime() + timeZoneOffset),
    ends_at: new Date(args.event._instance.range.end.getTime() + timeZoneOffset),
  })
    .unwrap()
    .then(() => {
      checkForTimeMatches(~~args.event._def.publicId, schedulerAPI);
      checkForActionInPast(args.event._instance.range.start.getTime());
    })
    .catch(() => {
      toast.error(`Произошла техническая ошибка, попробуйте редактировать визит позже`);
      args.revert();
    });
};

function trackDraggingAppointmentPosition() {
  const currentDraggingAppointments = document.getElementsByClassName('currentDraggingAppointment');
  let cancellingMarker = false;
  const func = () => {
    if (currentDraggingAppointments[1]) {
      const position = currentDraggingAppointments[1].getBoundingClientRect();
      if (cancellingMarker) {
        if (position.top === 0 && position.bottom === 0 && position.left === 0 && position.right === 0) {
          cancellingMarker = false;
        }
      } else {
        if (position.top !== 0 || position.bottom !== 0 || position.left !== 0 || position.right !== 0) {
          if (!document.getElementById('cancelMessageOverlay')) {
            cancellingMarker = true;
            currentDraggingAppointments[1].firstChild.style.cssText += 'filter: blur(4px)';
            currentDraggingAppointments[1].firstChild.firstChild.style.cssText += 'display: flex !important;';
            const cancelMessageOverlay = document.createElement('div');
            cancelMessageOverlay.setAttribute('id', 'cancelMessageOverlay');
            cancelMessageOverlay.innerHTML = 'Отменить редактирование визита';
            cancelMessageOverlay.style.cssText =
              'z-index: 999; position: absolute; left: 0px; right: 0px; bottom: 0; top: 0; text-align: center; font-size: 12px; line-height: 120%; font-weight: 500; color: #515D6B; display: flex; align-items: center; padding: 0 20px;';
            currentDraggingAppointments[1].appendChild(cancelMessageOverlay);
          }
        }
      }
    }
  };
  func();
}

// Функция, вызывающаяся при отпускании визита после перетаскивания (ВЫЗЫВАЕТСЯ В ЛЮБОМ СЛУЧАЕ)
export const eventDragStop = (args, schedulerAPI, handleStopEdit) => {
  args.event.setExtendedProp('isDraggingOrResizing', false);
  const targetId = args.jsEvent.composedPath().length ? args.jsEvent.composedPath()[0].id : '';

  if (targetId === 'cancelMessageOverlay') {
    const resourcesElements = document.querySelectorAll('td[data-resource-id]');
    const targetResourceElement = [...resourcesElements].filter((resource) => {
      const resourcePosition = resource.getBoundingClientRect();
      return (
        args.jsEvent.offsetX >= resourcePosition.left &&
        args.jsEvent.offsetX <= resourcePosition.right &&
        args.jsEvent.offsetY >= resourcePosition.top &&
        args.jsEvent.offsetY <= resourcePosition.bottom
      );
    });
    if (targetResourceElement.length) {
      toast.error(
        `На это время уже есть запись в кабинете ${
          schedulerAPI
            .getResources()
            .find((resource) => resource._resource.id === targetResourceElement[0].attributes[3].value)._resource.title
        }`,
      );
    }
  }

  document.removeEventListener('mousemove', trackDraggingAppointmentPosition);
  const currentDraggingAppointments = document.getElementsByClassName('currentDraggingAppointment');
  [...currentDraggingAppointments].forEach((el) => el.classList.remove('currentDraggingAppointment'));
  handleStopEdit(~~args.event._def.publicId);
};

// Функция, вызывающаяся при отпускании визита после растягивания
export const eventResize = async (args, updateAppointment, schedulerAPI) => {
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  updateAppointment({
    id: ~~args.event._def.publicId,
    clinic: args.event._def.extendedProps.doctor.clinic,
    starts_at: new Date(args.event._instance.range.start.getTime() + timeZoneOffset),
    ends_at: new Date(args.event._instance.range.end.getTime() + timeZoneOffset),
  })
    .unwrap()
    .then(() => {
      checkForTimeMatches(~~args.event._def.publicId, schedulerAPI);
      checkForActionInPast(args.event._instance.range.start.getTime());
    })
    .catch(() => {
      toast.error(`Произошла техническая ошибка, попробуйте редактировать визит позже`);
      args.revert();
    });
};

// Прокрутка таблицы к определённой дате
export const scrollTableToDate = (dateToScroll, isInstantScroll = false) => {
  const tableElement = document.getElementById('TableWrapperToScroll');

  if ((dateToScroll === 'start' || dateToScroll === 'end') && tableElement) {
    const positionToScroll = dateToScroll === 'start' ? 0 : tableElement.scrollWidth;
    tableElement.scroll({
      top: 0,
      left: positionToScroll,
      behavior: 'smooth',
    });
    return;
  }

  const selectedColumns = tableElement.querySelectorAll('td.selectedDate');
  const selectedColumnsCenters = Array.from(selectedColumns).map(
    (column) => column.offsetLeft + column.offsetWidth / 2,
  );
  const selectedColumnsCenter = selectedColumnsCenters.reduce((a, b) => a + b, 0) / selectedColumnsCenters.length;

  tableElement.scroll({
    top: 0,
    left: selectedColumnsCenter - 25 - tableElement.offsetWidth / 2,
    behavior: isInstantScroll ? 'auto' : 'smooth',
  });
};

// Принимает массив всех сессий/визитов и массив докторов, возвращает даты для отрисовки
export const calculateDatesToRender = (events, doctors, holidays, workdays) => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const today = new Date().setHours(0, 0, 0, 0);
  const allEvents = events.filter((event) => event._def.ui.display !== 'block');
  const doctorsIDs = doctors.map((doctor) => doctor.id);
  const holidaysNonWorkingDates = holidays
    .filter((holiday) => !holiday.is_workday)
    .map((holiday) => new Date(holiday.date).getTime() + timezoneOffset);
  const holidaysWorkingDates = holidays
    .filter((holiday) => holiday.is_workday)
    .map((holiday) => new Date(holiday.date).getTime() + timezoneOffset);
  const weekdaysNonWorkingIndexes = workdays
    .filter((workday) => !workday.is_workday)
    .map((workday) => (workday.id === 7 ? 0 : workday.id));

  // Получаем уникальный, очищенный от null и отсортированный по ASC массив дат
  // (день без часов, минут и секунд) сессий, которые подошли по фильтру врачей
  const filteredDoctorsDates = [
    ...new Set(
      allEvents
        .map((event) =>
          doctorsIDs.includes(event._def.extendedProps.stafferId || event._def.extendedProps.doctor.id)
            ? new Date(event._instance.range.start).setHours(0, 0, 0, 0)
            : null,
        )
        .filter((date) => !!date)
        .filter((date) => date >= today)
        .filter((date) => {
          if (!holidaysNonWorkingDates.includes(date) && !holidaysWorkingDates.includes(date)) {
            return !weekdaysNonWorkingIndexes.includes(new Date(date).getDay());
          } else {
            return !holidaysNonWorkingDates.includes(date);
          }
        })
        .sort(),
    ),
  ];

  return filteredDoctorsDates;
};

const checkForCommon = (setA, setB) => {
  for (const elem of setA) {
    if (setB.has(elem)) {
      return true;
    }
  }
  return false;
};

// Функция, которая присваивает колонкам таблицы определённые CSS-классы
export const checkForHideCells = (args, schedulerAPI) => {
  if (schedulerAPI) {
    const eventsHashMap = schedulerAPI.getOption('navLinks');
    const selectedDate = schedulerAPI.getOption('buttonIcons');

    const cabinets = schedulerAPI.getOption('resources');
    const initialCabinets = schedulerAPI.getOption('viewSkeletonRender');
    const doctors = schedulerAPI.getOption('dayPopoverFormat');

    const cabinetsIDs = new Set([...cabinets.map((c) => c.id)]);
    const initialCabinetsIDs = new Set([...initialCabinets.map((ic) => ic.id)]);
    const doctorsIDs = new Set([...doctors.map((d) => d.id)]);

    // Если есть выбранная дата, и она равна текущей дате - надо вернуть класс подсветки колонки синей рамкой
    if (selectedDate && !doctorsIDs.size) {
      if (selectedDate === args.date.getTime()) {
        const cabinetsToCalculateFrom = cabinetsIDs.size ? [...cabinetsIDs] : initialCabinetsIDs;
        const lastCabinetId = cabinetsToCalculateFrom.reduce(
          (max, cabinetID) => (cabinetID > max ? cabinetID : max),
          cabinetsToCalculateFrom[0],
        );

        if (args.resource._resource.id == lastCabinetId) {
          return 'selectedDate custom_highlighted_last custom_shown';
        } else {
          return 'selectedDate custom_highlighted custom_shown';
        }
      }
    }

    // В фильтрах есть врачи - проверяем столбик на...
    if (doctorsIDs.size) {
      const columnCabinet = args.resource._resource.id;
      const columnFormattedDate = format(args.date, 'yyyy-MM-dd');

      // ...наличие сессии/визита в столбике
      const cabinetAndDateEventsGroupDoctors = eventsHashMap[`${columnCabinet}-${columnFormattedDate}`];
      if (!cabinetAndDateEventsGroupDoctors) return 'custom_hidden';

      // ...совпадение докторов из фильтра, и докторов которые есть у столбика
      const doEventDoctorsMatch = checkForCommon(cabinetAndDateEventsGroupDoctors, doctorsIDs);
      // ...совпадение кабинетов из фильтра, и кабинета столбика (если кабинетов в фильтре нет - проверка игнорируется)
      const isColumnCabinetMatch = cabinetsIDs.size === 0 || cabinetsIDs.has(Number(columnCabinet));
      if (doEventDoctorsMatch && isColumnCabinetMatch) {
        return `custom_shown${args.date.getTime() === new Date().setHours(0, 0, 0, 0) ? ' custom_today' : ''}`;
      }

      return 'custom_hidden';
    }
  }

  // Если в фильтрах нет врачей - ничего не прячем
  return `custom_shown${args.date.getTime() === new Date().setHours(0, 0, 0, 0) ? ' custom_today' : ''}`;
};

// Расчитывает и устанавливает ширину таблицы
export const calculateTableWidth = (
  schedulerAPI,
  datesInRange,
  datesToRender,
  cabinets,
  initialCabinets,
  selectedDate,
) => {
  if (schedulerAPI) {
    let datesCounter = 1;
    const datesToCalculateFrom = datesToRender && datesToRender.length ? datesToRender : datesInRange;
    if (datesToCalculateFrom.length) {
      datesCounter = datesToCalculateFrom.length;
      if (selectedDate) {
        datesCounter = datesToCalculateFrom.includes(selectedDate)
          ? datesToCalculateFrom.length
          : datesToCalculateFrom.length + 1;
      }
    }

    if (!(datesToRender && datesToRender.length)) {
      datesCounter = datesCounter - 1;
    }

    const cabinetsCounter = cabinets.length ? cabinets.length : initialCabinets.length;
    const tableСellWidth = 188;
    const timeAxisWidth = 50;
    const verticalScrollWidth = 7;

    return verticalScrollWidth + timeAxisWidth + datesCounter * cabinetsCounter * tableСellWidth;
  }
};

// Вспомогательная функция для передачи ширины таблицы в стилях
export const getTableWidth = (tableWidth, screenWidth) => {
  if (!tableWidth) {
    return 'unset !important';
  } else if (tableWidth >= screenWidth) {
    return 'calc(100vw - 45px) !important';
  } else {
    // 7 - это толщина скроллбара, её надо прибавить. Иначе скроллбар "вминается" в ширину таблицы
    return `${tableWidth + 7}px !important`;
  }
};

export const scrollTableByValue = (value) => {
  const tableElement = document.getElementById('TableWrapperToScroll');
  const columnWidth = 188;

  const positionToScroll = value * columnWidth;

  tableElement.scroll({
    top: 0,
    left: tableElement.scrollLeft + positionToScroll,
    behavior: 'smooth',
  });
};

// Логика для реализации переключения дат с перетаскивающимся визитом.
// Договорились, что пока это не будет делаться

// export const makeDragTargetElement = (element, dragTargetID, callback) => {
//   const getCssText = (elementPos) => {
//     return (`
//         z-index: 9999;
//         position: absolute;
//         background-color: red;
//         width: ${elementPos.width}px;
//         height: ${elementPos.height}px;
//         left: ${elementPos.left}px;
//         right: ${elementPos.right}px;
//         top: ${elementPos.top}px;
//         bottom: ${elementPos.bottom}px;
//       `);
//   };

//   const elementPos = element.getBoundingClientRect();
//   const elementDragTarget = document.createElement('div');
//   elementDragTarget.setAttribute('id', dragTargetID);
//   elementDragTarget.style.cssText = getCssText(elementPos);
//   document.body.appendChild(elementDragTarget);
//   elementDragTarget.addEventListener('mouseenter', callback);
// };

// export const deleteDragTargetElement = (dragTargetID) => {
//   const dragTargetElement = document.getElementById(dragTargetID);
//   dragTargetElement && dragTargetElement.remove();
// }

// Функция, дающая переносимому визиту нужные свойства (активируется при начале перетаскивания визита)
export const eventDragStart = (args, handleStartEdit) => {
  args.event.setExtendedProp('isHidden', true);
  // Из-за особенностей API, действия с args.el вызывают изменения сразу двух элементов: невидимому в таблице, и тому что следует за курсором
  // Выход - присвоить им уникальный класс и выбрать второй визит (который следует за курсором)
  args.el.classList.add('currentDraggingAppointment');
  handleStartEdit(~~args.event._def.publicId);
  document.addEventListener('mousemove', trackDraggingAppointmentPosition);
  args.event.setExtendedProp('isDraggingOrResizing', true);
  setTimeout(() => args.event.setExtendedProp('isHidden', false));
};

// Функция для склонения слов под количества чего-либо (1 минута, 2 минуты, 5 минут)
export const declension = (number, titles) => {
  const decCache = [];
  const decCases = [2, 0, 1, 1, 1, 2];
  if (!decCache[number])
    decCache[number] = number % 100 > 4 && number % 100 < 20 ? 2 : decCases[Math.min(number % 10, 5)];
  return titles[decCache[number]];
};

// Функция для получения строки статуса из кода статуса (SCH => Не подтверждён)
export const getStatusStringFromStatus = (value) => {
  switch (value) {
    case 'SCH':
      return 'Не подтверждён';
    case 'CNF':
      return 'Подтверждён';
    case 'ARR':
      return 'В клинике';
    case 'INP':
      return 'На лечении';
    case 'COM':
      return 'К оплате';
    default:
      return 'Статус';
  }
};

// Функция для перемещения/копирования визитов из окна редактирования визитов (с кнопками "переместить" / "копировать")
export const moveOrCopyAppointment = (
  id,
  starts_at,
  ends_at,
  cabinet,
  status,
  comment,
  patient,
  doctor,
  isIrrelevant = false,
  schedulerAPI,
  dispatch,
  handleStopEdit,
  isCopying,
) => {
  // Получение визита для клонирования и элементов календаря, для отслеживания кликов
  const appointmentElement = document.getElementById(`appointment-${id}`);
  const arrowButtonLeftElement = document.getElementById('arrowButtonLeft');
  const openButtonElement = document.getElementById('openButton');
  const arrowButtonRightElement = document.getElementById('arrowButtonRight');
  const tableElement = document.getElementById('TableWrapperToScroll');
  const clickedButtonElement = document.getElementById(`${isCopying ? 'copyButton' : 'moveButton'}`);

  // Создание клона визита, прикрепление его к курсору
  const clone = appointmentElement.offsetParent.offsetParent.offsetParent.cloneNode(true);
  clone.style.cssText = `width: 188px; z-index: 9999; height: ${
    ((new Date(ends_at).getTime() - new Date(starts_at).getTime()) / 1800000) * 34.5
  }px !important; position: fixed;`;
  clone.firstChild.style.cssText =
    'background-color: unset !important; box-shadow: unset !important; border: none !important; ';
  clone.setAttribute('id', 'draggingAppointment');

  document.body.appendChild(clone);

  // Создание элемента-оверлея, кототорый будет сверху клона с сообщением "отменить визит"
  const cancelMessageOverlay = document.createElement('div');
  cancelMessageOverlay.setAttribute('id', 'cancelMessageOverlay');
  cancelMessageOverlay.innerHTML = 'Отменить редактирование визита';
  cancelMessageOverlay.style.cssText =
    'z-index: 999; position: absolute; left: 0px; right: 0px; bottom: 0; top: 0; text-align: center; font-size: 12px; line-height: 120%; font-weight: 500; color: #515D6B; display: flex; align-items: center; padding: 0 20px;';
  let isCanceling = false;

  function onMouseMove(e) {
    const datePickerPaperElement = document.getElementById('datePickerPaper');
    // Если флаг наличия оверлея неактивен - надо проверять на кейс присутствия курсора в "зоне сброса" визита
    if (!isCanceling) {
      // Мышь движется по зонам сброса
      if (
        datePickerPaperElement
          ? !datePickerPaperElement.contains(e.target)
          : true &&
            !tableElement.contains(e.target) &&
            !arrowButtonLeftElement.contains(e.target) &&
            !openButtonElement.contains(e.target) &&
            !arrowButtonRightElement.contains(e.target) &&
            !clickedButtonElement.contains(e.target)
      ) {
        // Даём оверлей
        clone.firstChild.style.cssText += 'filter: blur(4px)';
        clone.appendChild(cancelMessageOverlay);
        isCanceling = true;
      }
      // Если флаг наличия оверлея активен - надо проверять на кейс присутствия курсора в "зоне активности" визита
    } else {
      // Мышь движется по зонам активности
      if (
        datePickerPaperElement
          ? !datePickerPaperElement.contains(e.target)
          : false ||
            tableElement.contains(e.target) ||
            arrowButtonLeftElement.contains(e.target) ||
            openButtonElement.contains(e.target) ||
            arrowButtonRightElement.contains(e.target) ||
            clickedButtonElement.contains(e.target)
      ) {
        // Убираем оверлей
        clone.firstChild.style.cssText =
          'background-color: unset !important; box-shadow: unset !important; border: none !important;';
        cancelMessageOverlay.remove();
        isCanceling = false;
      }
    }
    clone.style.left = e.pageX + 20 + 'px';
    clone.style.top = e.pageY + 20 + 'px';
  }

  document.addEventListener('mousemove', onMouseMove);
  dispatch(
    setDraggingElement({
      id,
      cabinet,
      status,
      comment,
      patient,
      doctor,
      ends_at,
      starts_at,
      isIrrelevant,
    }),
  );
  isCopying && dispatch(setIsCopying(true));
  !isCopying &&
    schedulerAPI &&
    getFilteredEvents(schedulerAPI.getEvents(), 'appointments', id)[0].setExtendedProp('isHidden', true);
  handleStopEdit();

  // Установка прослушки на клик, с хендлом сброса перемещения (если мы кликнули не в таблицу, и не на календарь)
  const onOutsideClick = (event) => {
    const datePickerPaperElement = document.getElementById('datePickerPaper');
    const isClickedInside =
      (datePickerPaperElement ? datePickerPaperElement.contains(event.target) : false) ||
      event.target.id === 'todayButton' ||
      arrowButtonLeftElement.contains(event.target) ||
      openButtonElement.contains(event.target) ||
      arrowButtonRightElement.contains(event.target) ||
      clickedButtonElement.contains(event.target);

    if (!isClickedInside) {
      clone.remove();
      !(event.target.className === 'fc-timegrid-body') &&
        !isCopying &&
        schedulerAPI &&
        getFilteredEvents(schedulerAPI.getEvents(), 'appointments', id)[0].setExtendedProp('isHidden', false);

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('click', onOutsideClick);
      dispatch(setDraggingElement(null));
    }
  };
  document.addEventListener('click', onOutsideClick);
};

// Функция расчёта высоты таблицы
export const getTableHeight = (slotMinTime, slotMaxTime) => {
  const minTimeHours = Number(slotMinTime.substring(0, slotMinTime.indexOf(':')));
  const maxTimeHours = Number(slotMaxTime.substring(0, slotMaxTime.indexOf(':')));
  const minTimeMinutes = Number(slotMinTime.substring(slotMinTime.indexOf(':') + 1, slotMinTime.lastIndexOf(':')));
  const maxTimeMinutes = Number(slotMaxTime.substring(slotMaxTime.indexOf(':') + 1, slotMaxTime.lastIndexOf(':')));

  const timeDeltaInMinutes = (maxTimeHours - minTimeHours) * 60 + (maxTimeMinutes - minTimeMinutes);
  // const tableRowsCounter = timeDeltaInMinutes / 30;
  const tableRowsCounter = timeDeltaInMinutes / 15;

  // return (tableRowsCounter + 2) * 32;
  return (tableRowsCounter + 3) * 16;
};

//Получает информацию о рабочих днях и возвращает массив готовых блокеров для таблицы
export const getConstraintBlockers = (workdays, holidays, datesInRange, selectedDate, filterCabinets, schedulerAPI) => {
  let blockersArray = [];

  // Если в диапазоне только одна дата (это когда при выборе врачей нет никаких дней) - никаких блокеров расчитывать не надо - всё равно будет пустая таблица
  if (datesInRange.length === 1) return blockersArray;

  const currentResourcesIds = filterCabinets.map((cabinet) => cabinet.id);
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const blockerSettings = {
    display: 'block',
    resourceIds: currentResourcesIds,
    editable: false,
    startEditable: false,
    durationEditable: false,
    resourceEditable: false,
  };

  const cuttedDateRange = datesInRange.slice(0, datesInRange.length - 1);

  const datesToCalculateBlockersFrom =
    !selectedDate || datesInRange.includes(selectedDate)
      ? cuttedDateRange
      : [...cuttedDateRange, selectedDate].sort((a, b) => a - b);

  const uniqueActualWeekdays = [
    ...new Set(datesToCalculateBlockersFrom.map((date) => weekdaysKeys[new Date(date).getDay()])),
  ];

  const filteredWorkdays = workdays.filter((workday) => uniqueActualWeekdays.includes(workday.weekday));

  const holidaysInDatesToRender = holidays.filter((holiday) =>
    datesToCalculateBlockersFrom.includes(new Date(holiday.date).getTime() + timezoneOffset),
  );

  const minWorkdayStartTime = [
    ...filteredWorkdays.filter((workday) => workday.is_workday).map((workday) => workday.time_start),
    ...holidaysInDatesToRender.filter((holiday) => holiday.is_workday).map((holiday) => holiday.time_start),
  ].sort()[0];

  const maxWorkdayEndTime = [
    ...filteredWorkdays.filter((workday) => workday.is_workday).map((workday) => workday.time_end),
    ...holidaysInDatesToRender.filter((holiday) => holiday.is_workday).map((holiday) => holiday.time_end),
  ]
    .sort()
    .pop();

  //БЫСТРЫЙ ВРЕМЕННЫЙ ФИКС: ЕСЛИ ОТКРЫВАЕТСЯ ТАБЛИЦА, И НАЧАЛЬНЫЕ ДАТЫ (СЕГОДНЯ И ЕЩЁ НЕСКОЛЬКО ДНЕЙ ПОСЛЕ) - НЕРАБОЧИЕ
  //ТО ВОЗНИКАЕТ ОШИБКА - НЕВОЗМОЖНО ОПРЕДЕЛИТЬ НАЧАЛО И КОНЕЦ ТАБЛИЦЫ
  //ВРЕМЕННОЕ РЕШЕНИЕ - ПОСТАВИТЬ ДЕФОЛТНОЕ ЗНАЧЕНИЕ
  //TODO: ПРИ ИНИЦИАЛИЗАЦИИ, СДЕЛАТЬ ПРОВЕРКУ ИНИЦИАЛИЗАЦИОННОГО ДИАПАЗОНА ДАТ: ЕСЛИ В НЁМ ЕСТЬ ХОТЬ ОДИН НЕРАБОЧИЙ ДЕНЬ - НАДО РАСШИРЯТЬ ДИАПАЗОН
  //...ТО ЕСТЬ, ЗАХВАТЫВАТЬ ЕЩЁ ДЕНЬ В БУДУЩЕМ. ТАКИМ ОБРАЗОМ, ТАКОЙ КЕЙС СТАНЕТ НЕВОЗМОЖНЫМ

  schedulerAPI.setOption('slotMinTime', minWorkdayStartTime || '09:00:00');
  schedulerAPI.setOption('slotMaxTime', maxWorkdayEndTime || '18:00:00');
  schedulerAPI.setOption(
    'contentHeight',
    getTableHeight({
      slotMinTime: minWorkdayStartTime || '09:00:00',
      slotMaxTime: maxWorkdayEndTime || '18:00:00',
    }),
  );

  datesToCalculateBlockersFrom.forEach((date) => {
    const targetDate = new Date(date - timezoneOffset);
    const dateString = targetDate.toISOString();

    let earlyBlockerEnd;
    let lateBlockerStart;
    let blockWholeDay = false;

    const existingHoliday = holidaysInDatesToRender.find(
      (holiday) => holiday.date === dateString.substring(0, dateString.indexOf('T')),
    );

    // Если для этой даты есть точечная настройка - определяются блокеры для этой даты из точечной настройки
    if (existingHoliday) {
      if (existingHoliday?.is_workday) {
        earlyBlockerEnd = existingHoliday.time_start;
        lateBlockerStart = existingHoliday.time_end;
      } else {
        blockWholeDay = true;
      }
    }

    // Иначе - для этой даты точно есть настройка по дню недели
    else {
      const targetWorkday = workdays.find((workday) => workday.weekday === weekdaysKeys[new Date(targetDate).getDay()]);
      if (targetWorkday?.is_workday) {
        earlyBlockerEnd = targetWorkday.time_start;
        lateBlockerStart = targetWorkday.time_end;
      } else {
        blockWholeDay = true;
      }
    }

    // Добавление в массив блокеров новых объектов, согласно настройками

    // Если есть флаг блокера на весь день (т.е это точечный или неточечный выходной) - блокер идёт от верха до низа таблицы
    // Если такое сработало - больше на этой дате никаких блокеров точно не будет
    if (blockWholeDay) {
      blockersArray = [
        ...blockersArray,
        {
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${minWorkdayStartTime}.000Z`).getTime() +
            timezoneOffset,
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${maxWorkdayEndTime}.000Z`).getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
      return;
    }

    // Если минимальное время таблицы не совпало со временем конца раннего блокера - между двумя точками есть разница, надо создавать блокер сверху чтоб её закрыть
    if (minWorkdayStartTime !== earlyBlockerEnd) {
      blockersArray = [
        ...blockersArray,
        {
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${minWorkdayStartTime}.000Z`).getTime() +
            timezoneOffset,
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + earlyBlockerEnd + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }

    // Если максимальное время таблицы не совпало со временем начала позденго блокера - между двумя точками есть разница, надо создавать блокер снизу чтоб её закрыть
    if (maxWorkdayEndTime !== lateBlockerStart) {
      blockersArray = [
        ...blockersArray,
        {
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${maxWorkdayEndTime}.000Z`).getTime() +
            timezoneOffset,
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + lateBlockerStart + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }
  });

  return blockersArray;
};

// Функция расчёта стартового времени и конечного времени в таблице
export const getMinMaxTime = (workdays, holidays) => {
  const { dateRange } = store.getState().reworkedSchedule;

  // Попытка найти запись о конкретной дате в списке кастомных дат
  const existingHoliday = holidays.find(
    (holiday) =>
      holiday.date === new Date(dateRange.start - new Date().getTimezoneOffset() * 60000).toISOString().slice(0, 10),
  );

  // Если дата есть - функция вернёт данные по этому дню. Если нет - будет брать информацию из настройки по дням недели
  if (existingHoliday) {
    return {
      slotMinTime: existingHoliday.time_start,
      slotMaxTime: existingHoliday.time_end,
    };
  } else {
    const currentWeekday = workdays.find(
      (workday) => workday.weekday === weekdaysKeys[new Date(dateRange.start).getDay()],
    );

    // Если отображаемый день один - ограничения в таблице отображаются для одного дня
    if (dateRange.start === dateRange.end && currentWeekday) {
      return {
        slotMinTime: currentWeekday.time_start,
        slotMaxTime: currentWeekday.time_end,
      };
    }

    // Иначе - дефолтные ограничения, пока не пишем других
    return { slotMinTime: '09:00:00', slotMaxTime: '23:00:00' };
  }
};

const isWorkingDayInRange = (dateRange, workingWorkdays, nonWorkingHolidays) => {
  const datesInRange = eachDayOfInterval(dateRange).map((e) => e.getTime());
  const cuttedDateRange = datesInRange.length > 1 ? datesInRange.slice(0, datesInRange.length - 1) : datesInRange;

  if (nonWorkingHolidays.length) {
    const nonWorkingHolidaysFormatted = nonWorkingHolidays.map((holiday) =>
      new Date(holiday.date).setHours(0, 0, 0, 0),
    );

    if (haveIntersection(nonWorkingHolidaysFormatted, cuttedDateRange)) {
      return false;
    }
  }

  if (workingWorkdays.length) {
    const workingWorkdaysWeekdays = workingWorkdays.map((workday) => workday.id);
    const datesInRangeWeekdays = new Set([
      ...cuttedDateRange.map((date) => {
        const myDate = new Date(date).getDay();
        return myDate === 0 ? 7 : myDate;
      }),
    ]);

    if (haveIntersection(workingWorkdaysWeekdays, datesInRangeWeekdays)) {
      return true;
    }
  }

  return false;
};

export const getInitialDateRange = (filterCabinets, cabinets, workdays, holidays) => {
  const columnWidth = 191;
  const screenWidth = window.innerWidth;

  const cabinetsCount = filterCabinets.length || cabinets.length;
  const columnsCount = Math.ceil(screenWidth / columnWidth);
  const datesCount = Math.ceil(columnsCount / cabinetsCount);

  const dateRangeStart = new Date().setHours(0, 0, 0, 0);
  const dateRangeEnd = addDays(dateRangeStart, datesCount).setHours(0, 0, 0, 0);
  const dateRange = { start: dateRangeStart, end: dateRangeEnd || addDays(dateRangeStart, 1).setHours(0, 0, 0, 0) };

  const areAllWorkdaysAreNonWorking = workdays.every((item) => item.is_workday === false);

  if (!areAllWorkdaysAreNonWorking) {
    const nonWorkingHolidays = holidays
      .filter((holiday) => !holiday.is_workday)
      .filter((holiday) => new Date(holiday.date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));
    const workingWorkdays = workdays.filter((holiday) => holiday.is_workday);

    while (!isWorkingDayInRange(dateRange, workingWorkdays, nonWorkingHolidays)) {
      dateRange.end = addDays(dateRange.end, 1).setHours(0, 0, 0, 0);
    }
  }

  return dateRange;
};

export const setInitialDateRange = (
  cabinets,
  initialCabinets,
  schedulerAPI,
  dispatch,
  workdays,
  holidays,
  needToScroll = true,
) => {
  const newDateRange = getInitialDateRange(cabinets, initialCabinets, workdays, holidays);
  schedulerAPI.setOption('visibleRange', newDateRange);

  dispatch(setDateRange(newDateRange));
  needToScroll && setTimeout(() => scrollTableToDate('start'));
};

// Сетает в экземляр таблицы данные из redux (используется при реинициализации таблицы)
export const reinitializeTableData = (dispatch, workdays, holidays) => {
  const {
    schedulerAPI,
    dateRange,
    datesToRender,
    cabinets,
    selectedDate,
    initialCabinets,
    currentSessions,
    currentAppointments,
    doctors,
  } = store.getState().reworkedSchedule;
  if (schedulerAPI) {
    const minMaxTime = getMinMaxTime(workdays, holidays);
    schedulerAPI.batchRendering(function () {
      if (doctors.length) {
        schedulerAPI.setOption('visibleRange', dateRange);
      } else {
        setInitialDateRange(cabinets, initialCabinets, schedulerAPI, dispatch, workdays, holidays, false);
      }

      schedulerAPI.setOption('resources', cabinets.length ? cabinets : initialCabinets);
      schedulerAPI.setOption('slotMinTime', minMaxTime.slotMinTime);
      schedulerAPI.setOption('slotMaxTime', minMaxTime.slotMaxTime);
      schedulerAPI.setOption('contentHeight', getTableHeight(minMaxTime));
      datesToRender.length &&
        schedulerAPI.setOption(
          'titleRangeSeparator',
          calculateDatesToRender(schedulerAPI.getEvents(), doctors, holidays, workdays),
        );
      selectedDate && schedulerAPI.setOption('buttonIcons', selectedDate);
      schedulerAPI.getEvents().forEach((event) => event.remove());
      schedulerAPI.addEventSource([...currentAppointments, ...currentSessions]);
    });
    dispatch(setBlockers({ workdays, holidays }));
    selectedDate && scrollTableToDate(selectedDate, true);
  }
};

// Функция для проверки рабочести-нерабочести дня
export const isCurrentWeekdayAWorkday = (workdays) => {
  const { dateRange } = store.getState().reworkedSchedule;
  return workdays.find((workday) => workday.weekday === weekdaysKeys[new Date(dateRange.start).getDay()]).is_workday;
};

// Функция для обрезания строки на определенное количество символов
export const truncateString = (string, limit) => {
  if (string.length > limit) {
    return string.substring(0, limit) + '...';
  } else {
    return string;
  }
};

/* eslint-disable no-fallthrough */
// Функция для вычисления рабочести-нерабочести текущего дня таблицы
export const isCurrentDayIsNonWorking = (
  areHolidaysLoading = false,
  areWorkdaysLoading = false,
  datesInRange,
  workdays,
  currentHoliday,
) => {
  const isWeekdayWorking = workdays.length ? isCurrentWeekdayAWorkday(workdays) : false;
  const isDayCustom = !!currentHoliday;
  const isDayWorking = currentHoliday && currentHoliday.is_workday;

  switch (true) {
    // Идёт загрузка расписания и кастомных дней, возвращаю false. День рабочий.
    case areHolidaysLoading && areWorkdaysLoading:
      return false;
    // Несколько дней, возвращаю false. День рабочий.
    case datesInRange.length > 1:
      return false;
    // День недели рабочий, кастомного дня нет. Возвращаю false, день рабочий.
    case isWeekdayWorking && !isDayCustom:
      return false;
    // День недели рабочий, кастомный день рабочий. Возвращаю false, день рабочий.
    case isWeekdayWorking && isDayWorking:
      return false;
    // День недели рабочий, кастомный день нерабочий. Возвращаю true, день нерабочий.
    case isWeekdayWorking && !isDayWorking:
      return true;
    // День недели нерабочий, кастомного дня нет. Возвращаю true, день нерабочий.
    case !isWeekdayWorking && !isDayCustom:
      return true;
    // День недели нерабочий, кастомный день рабочий. Возвращаю false, день рабочий.
    case !isWeekdayWorking && isDayWorking:
      return false;
    // День недели нерабочий, кастомный день нерабочий. Возвращаю true, день нерабочий.
    case !isWeekdayWorking && !isDayWorking:
      return true;
  }
};

export const eventResizeStart = (args, handleStartEdit) => {
  args.event.setExtendedProp('isDraggingOrResizing', true);
  handleStartEdit(~~args.event._def.publicId);
};

export const eventResizeStop = (args, handleStopEdit) => {
  args.event.setExtendedProp('isDraggingOrResizing', false);
  handleStopEdit(~~args.event._def.publicId);
};

export const deleteTableAppointment = (id, schedulerAPI) => {
  const targetAppointment = getFilteredEvents(schedulerAPI.getEvents(), 'appointments', id)[0];

  if (targetAppointment) {
    targetAppointment.remove();
  }
};

export const createTableAppointment = (appointmentData, schedulerAPI) => {
  const newAppointment = {
    ...appointmentData,
    resourceId: appointmentData.cabinet,
    end: appointmentData.ends_at,
    start: appointmentData.starts_at,
    title: `${appointmentData.patient.last_name} ${
      appointmentData.patient.first_name[0]
    }. ${appointmentData.patient.second_name?.[0]?.concat('.')}`,
  };

  schedulerAPI.addEvent(newAppointment);
};

/**
 * Фильтрует визиты с бека по критериям.
 *
 * @param {Appointment[]} appointments - Массив визитов для фильтрации.
 * @param {number|string} [id] - ID визита для фильтрации.
 * @param {Date|number|string} [start] - Дата и время начала визита для фильтрации.
 * @param {Date|number|string} [end] - Дата и время начала окончания для фильтрации.
 * @param {string|number} [cabinet] - ID кабинета для фильтрации.
 * @returns {Appointment[]} Массив отфильтрованных визитов.
 */
export const filterBackendAppointments: TFilterBackendAppointments = (
  appointments,
  id = undefined,
  start = undefined,
  end = undefined,
  cabinet = undefined,
) => {
  const filteredAppointments = appointments.filter((appointment) => {
    if (appointment.cancel_reason) {
      return false;
    }
    if (id && appointment.id != id) {
      return false;
    }
    if (cabinet && appointment.cabinet != cabinet) {
      return false;
    }
    if (start && end) {
      const isOverlapping = areIntervalsOverlapping(
        { start: new Date(start), end: new Date(end) },
        {
          start: new Date(appointment.starts_at),
          end: new Date(appointment.ends_at),
        },
      );
      if (!isOverlapping) {
        return false;
      }
    }
    return true;
  });
  return filteredAppointments;
};

// Отфильтровать события и получить событие по определенной дате
export const getEventsByDate = (events, targetDay) => {
  const dateTrimmed = targetDay.setHours(0, 0, 0, 0);
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  return events
    .filter((event) => !Boolean(event._def.extendedProps.cancel_reason))
    .filter((event) => event._def.ui.display !== 'block')
    .filter((event) =>
      areIntervalsOverlapping(
        { start: dateTrimmed, end: addDays(dateTrimmed, 1) },
        {
          start: event._instance.range.start.getTime() + timeZoneOffset,
          end: event._instance.range.end.getTime() + timeZoneOffset,
        },
      ),
    );
};

// Проверка: пересекается ли хотя бы одно из массива табличных событий с указанным временем
export const checkEventsOverlapWithTimeInterval = (events, timeIntervalStart, timeIntervalEnd) => {
  const timeZoneOffset = new Date().getTimezoneOffset() * 60000;
  return events.filter((event) =>
    areIntervalsOverlapping(
      { start: timeIntervalStart, end: timeIntervalEnd },
      {
        start: event._instance.range.start.getTime() + timeZoneOffset,
        end: event._instance.range.end.getTime() + timeZoneOffset,
      },
    ),
  );
};

// Принимает врачей и визиты, возвращает кабинеты, на которых у врача есть назначенные визиты
export const getCabinetsWithDoctorsAppointments = (doctors, events, cabinetsDictionary) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const doctorsIds = doctors.map((doctor) => doctor.id);
  const doctorsEvents = events
    .filter((event) => !Boolean(event._def.extendedProps.cancel_reason))
    .filter((event) => event._def.ui.display !== 'block')
    .filter((event) => new Date(event._instance.range.start).setHours(0, 0, 0, 0) >= today)
    .filter((event) =>
      doctorsIds.includes(event._def.extendedProps?.stafferId || event._def.extendedProps?.doctor?.id),
    );
  const doctorsCabinetsIds = [...new Set([...doctorsEvents.map((event) => Number(event._def.resourceIds[0]))])];
  const doctorsCabinets = cabinetsDictionary.filter((cabinet) => doctorsCabinetsIds.includes(cabinet.id));

  return doctorsCabinets;
};

export const isWorkingDay = (date, nonWorkingDays, weekSchedule) => {
  const dateStringPlusOneDay = addDays(date, 1).toISOString().split('T')[0];

  const nonWorkingDay = nonWorkingDays.find((day) => day.date === dateStringPlusOneDay);
  if (nonWorkingDay) return nonWorkingDay.is_workday;

  const correctedIndex = date.getDay() === 0 ? 7 : date.getDay();
  const dayOfWeek = weekSchedule.find((weekday) => weekday.id === correctedIndex);
  return dayOfWeek.is_workday;
};

export const getHoursStrings = (start, end) => {
  const [startHours, startMinutes] = start.split(':');
  const [endHours, endMinutes] = end.split(':');

  const startDate = new Date(2000, 0, 1, startHours, startMinutes);
  const endDate = new Date(2000, 0, 1, endHours, endMinutes);

  const result = [];
  const currDate = new Date(startDate.getTime());

  while (currDate <= endDate) {
    const currHours = currDate.getHours().toString().padStart(2, '0');
    const currMinutes = currDate.getMinutes().toString().padStart(2, '0');

    result.push(`${currHours}:${currMinutes}`);

    currDate.setHours(currDate.getHours() + 1);
  }

  return result;
};

export const getEventsHashmap = (events) => {
  const today = new Date().setHours(0, 0, 0, 0);

  const filteredEvents = events
    .filter((event) => !Boolean(event.cancel_reason))
    .filter((event) => new Date(event.starts_at).setHours(0, 0, 0, 0) >= today);

  const formattedEvents = filteredEvents.map(({ cabinet, starts_at, doctor, worker }) => {
    return {
      cabinet,
      date: starts_at.substring(0, 10),
      doctor: doctor || worker,
    };
  });

  const groupedEvents = _.groupBy(formattedEvents, (event) => event.cabinet + '-' + event.date);

  const filledMap = _.mapValues(groupedEvents, function (eventGroup) {
    return new Set(eventGroup.map((event) => event.doctor));
  });

  return filledMap;
};

export const getAndSetTableWidth = (dispatch) =>
  Promise.resolve().then(() => {
    const rowElement = document.querySelectorAll("tr[role='row']")[2];
    const shownColumnsCount = rowElement ? rowElement.getElementsByClassName('custom_shown').length : 0;
    dispatch(setTableWidth(shownColumnsCount));
  });

// Получение количества видимых колонок таблицы. Завёрнуто в промис, чтобы подхватывать элементы с задержкой. Иначе они не найдутся.
export const getShownColumnsCount = () =>
  Promise.resolve().then(() => {
    try {
      const tableElement = document.getElementById('TableWrapperToScroll');
      const rowElement = tableElement.querySelectorAll("tr[role='row']")[1];
      const shownColumnsCount = rowElement.getElementsByClassName('custom_shown').length;

      return shownColumnsCount;
    } catch {
      console.error(
        'Не удалось получить контейнер колонок для подсчёта количества показываемых колонок (getShownColumnsCount)',
      );
    }
  });

export const createTimeAxisForTable = (schedulerAPI, isSchedulerUnactive, timeAxisComponent) => {
  const existingTimeAxisContainer = document.getElementById('timeAxisContainer');
  if (schedulerAPI && !isSchedulerUnactive && !existingTimeAxisContainer) {
    // Определение элемента, в который надо вставить временную шкалу
    const componentToInjectTimeAxisIn = document.querySelector('.fc-view-harness.fc-view-harness-active');

    // Если есть куда вставлять, определяю компонент временной шкалы и его контейнер (потому что *просто* компонент не вставить, нужен DOM-элемент)
    if (componentToInjectTimeAxisIn) {
      const timeAxisContainer = document.createElement('div');
      const topLeftLittleBlocker = document.createElement('div');
      // Присвоение id контейнеру, для стилизации через CSS
      // Помещение компонента временной шкалы в контейнер, рендер в DOM этого контейнера с компонентом внутри
      timeAxisContainer.setAttribute('id', `timeAxisContainer`);
      topLeftLittleBlocker.setAttribute('id', `topLeftLittleBlocker`);
      ReactDOM.render(timeAxisComponent, timeAxisContainer);
      componentToInjectTimeAxisIn.insertAdjacentElement('afterend', timeAxisContainer);
      componentToInjectTimeAxisIn.insertAdjacentElement('afterend', topLeftLittleBlocker);
    }

    // Добавление прослушки: если скроллится таблица (по Х), то за ней скроллится и шкала
    const tableElement = document.getElementById('TableWrapperToScroll');
    const timeAxisContainer = document.getElementById('timeAxisContainer');

    if (tableElement && timeAxisContainer) {
      const handleScroll = () => {
        timeAxisContainer.style.left = `${tableElement.scrollLeft}px`;
      };

      if (tableElement) {
        tableElement.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableElement) {
          tableElement.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }
};

// Возвращает минимальные и максимальные часы для таблицы
export const getSlotMinMaxTime = (workdays, holidays, datesInRange, selectedDate) => {
  // Проверка аргументов
  if (!Array.isArray(workdays) || !Array.isArray(holidays) || !Array.isArray(datesInRange)) {
    throw new Error('Invalid arguments: workdays, holidays, and datesInRange must be arrays.');
  }

  // Получение смещения по часовому поясу
  const timezoneOffset = new Date().getTimezoneOffset() * MS_PER_MINUTE;

  // Срезка последней даты из dateRange, если там больше одной даты
  const cuttedDateRange = datesInRange.length > 1 ? datesInRange.slice(0, datesInRange.length - 1) : datesInRange;

  // Получение дат для вычисления
  const datesToCalculateBlockersFrom =
    !selectedDate || datesInRange.includes(selectedDate)
      ? cuttedDateRange
      : [...cuttedDateRange, selectedDate].sort((a, b) => a - b);

  // Получение уникальных дней недели из дат
  const uniqueActualWeekdays = [...new Set(datesToCalculateBlockersFrom.map((date) => new Date(date).getDay()))];

  // Получение только тех настроек расписания клиники (ПО ДНЯМ НЕДЕЛИ), которые применимы к датам для вычисления
  const filteredWorkdays = filterWorkdays(workdays, uniqueActualWeekdays);

  // Получение только тех настроек расписания клиники (ПО ДНЯМ), которые применимы к датам для вычисления
  const holidaysInDatesToRender = holidays.filter((holiday) =>
    datesToCalculateBlockersFrom.includes(new Date(holiday.date).getTime() + timezoneOffset),
  );

  // Получение минимального старта дня
  const workdayStartTimes = filteredWorkdays.filter(isWorkday).map(getStartTime);
  const holidayStartTimes = holidaysInDatesToRender.filter(isWorkday).map(getStartTime);
  const minWorkdayStartTime = [...workdayStartTimes, ...holidayStartTimes].sort()[0];

  // Получение максимального старта дня
  const workdayEndTimes = filteredWorkdays.filter(isWorkday).map(getEndTime);
  const holidayEndTimes = holidaysInDatesToRender.filter(isWorkday).map(getEndTime);
  const maxWorkdayEndTime = [...workdayEndTimes, ...holidayEndTimes].sort().pop();

  return { slotMinTime: minWorkdayStartTime || '09:00:00', slotMaxTime: maxWorkdayEndTime || '18:00:00' };
};

// Возвращает массив готовых блокеров для таблицы
export const getBlockers = (workdays, holidays, datesInRange, selectedDate, cabinets, slotMinTime, slotMaxTime) => {
  let blockersArray = [];

  // Если в диапазоне только одна дата (это когда при выборе врачей нет никаких дней) - никаких блокеров расчитывать не надо - всё равно будет пустая таблица
  if (datesInRange.length === 1) return blockersArray;

  if (!cabinets?.length) return blockersArray;

  const currentResourcesIds = cabinets.map((cabinet) => cabinet.id);
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const blockerSettings = {
    display: 'block',
    resourceIds: currentResourcesIds,
    editable: false,
    startEditable: false,
    durationEditable: false,
    resourceEditable: false,
  };

  const cuttedDateRange = datesInRange.slice(0, datesInRange.length - 1);
  const datesToCalculateBlockersFrom =
    !selectedDate || datesInRange.includes(selectedDate)
      ? cuttedDateRange
      : [...cuttedDateRange, selectedDate].sort((a, b) => a - b);

  const holidaysInDatesToRender = holidays.filter((holiday) =>
    datesToCalculateBlockersFrom.includes(new Date(holiday.date).getTime() + timezoneOffset),
  );

  datesToCalculateBlockersFrom.forEach((date) => {
    const targetDate = new Date(date - timezoneOffset);
    const dateString = targetDate.toISOString();

    let earlyBlockerEnd;
    let lateBlockerStart;
    let blockWholeDay = false;

    const existingHoliday = holidaysInDatesToRender.find(
      (holiday) => holiday.date === dateString.substring(0, dateString.indexOf('T')),
    );

    // Если для этой даты есть точечная настройка - определяются блокеры для этой даты из точечной настройки
    if (existingHoliday) {
      if (existingHoliday.is_workday) {
        earlyBlockerEnd = existingHoliday.time_start;
        lateBlockerStart = existingHoliday.time_end;
      } else {
        blockWholeDay = true;
      }
    }
    // Иначе - для этой даты точно есть настройка по дню недели
    else {
      const targetWorkday = workdays.find((workday) => workday.weekday === weekdaysKeys[new Date(targetDate).getDay()]);
      if (targetWorkday?.is_workday) {
        earlyBlockerEnd = targetWorkday.time_start;
        lateBlockerStart = targetWorkday.time_end;
      } else {
        blockWholeDay = true;
      }
    }
    // Добавление в массив блокеров новых объектов, согласно настройками

    // Если есть флаг блокера на весь день (т.е это точечный или неточечный выходной) - блокер идёт от верха до низа таблицы
    if (blockWholeDay) {
      blockersArray = [
        ...blockersArray,
        {
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${slotMinTime}.000Z`).getTime() +
            timezoneOffset,
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${slotMaxTime}.000Z`).getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
      return;
    }

    // Если минимальное время таблицы не совпало со временем конца раннего блокера - между двумя точками есть разница, надо создавать блокер сверху чтоб её закрыть
    if (slotMinTime !== earlyBlockerEnd) {
      blockersArray = [
        ...blockersArray,
        {
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${slotMinTime}.000Z`).getTime() +
            timezoneOffset,
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + earlyBlockerEnd + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }

    // Если максимальное время таблицы не совпало со временем начала позденго блокера - между двумя точками есть разница, надо создавать блокер снизу чтоб её закрыть
    if (slotMaxTime !== lateBlockerStart) {
      blockersArray = [
        ...blockersArray,
        {
          end:
            new Date(dateString.substring(0, dateString.indexOf('T')) + `T${slotMaxTime}.000Z`).getTime() +
            timezoneOffset,
          start:
            new Date(dateString.substring(0, dateString.indexOf('T')) + 'T' + lateBlockerStart + '.000Z').getTime() +
            timezoneOffset,
          ...blockerSettings,
        },
      ];
    }
  });
  //  console.log(blockersArray)
  return blockersArray;
};

const logFalsyData = (data, funcName) => {
  const falsyInitializationTableDataKeys = [];
  Object.keys(data).forEach((key) => {
    if (typeof data[key] !== 'boolean' && !data[key]) {
      falsyInitializationTableDataKeys.push(key);
    }
  });
  if (falsyInitializationTableDataKeys.length) {
    console.warn(
      `В инициализацию данных для таблицы (${funcName}) попали пустые значения: ${falsyInitializationTableDataKeys.join(
        ', ',
      )}`,
    );
  }
};

export const setDateRangeToTable = (
  filterDoctors,
  filterCabinets,
  cabinets,
  holidays,
  workdays,
  selectedDate,
  schedulerAPI,
) => {
  let dateRangeToSet;
  if (filterDoctors.length) {
    const datesWithDoctorsEvents = calculateDatesToRender(schedulerAPI.getEvents(), filterDoctors, holidays, workdays);
    if (datesWithDoctorsEvents.length) {
      dateRangeToSet = {
        start: datesWithDoctorsEvents[0],
        end: datesWithDoctorsEvents[datesWithDoctorsEvents.length - 1],
      };
    }
  }
  if (!dateRangeToSet) {
    dateRangeToSet = getInitialDateRange(filterCabinets, cabinets, workdays, holidays);
  }
  if (selectedDate) {
    // Если новая выбранная дата меньше начала диапазона - диапазон расширяется влево
    if (selectedDate < dateRangeToSet.start) {
      dateRangeToSet = { ...dateRangeToSet, start: selectedDate - 86400000 };
    } // Если новая выбранная дата больше конца диапазона - диапазон расширяется вправо
    else if (selectedDate + 86400000 === dateRangeToSet.end || selectedDate >= dateRangeToSet.end) {
      dateRangeToSet = { ...dateRangeToSet, end: selectedDate + 86400000 * 2 };
    }
  }

  schedulerAPI.setOption('visibleRange', dateRangeToSet);
  return dateRangeToSet;
};

const setMinMaxSlotTime = (dateRangeToSet, selectedDate, workdays, holidays, schedulerAPI) => {
  const datesInRange = eachDayOfInterval(dateRangeToSet).map((e) => e.getTime());

  const { slotMinTime, slotMaxTime } = getSlotMinMaxTime(workdays, holidays, datesInRange, selectedDate);

  schedulerAPI.setOption('slotMinTime', slotMinTime);
  schedulerAPI.setOption('slotMaxTime', slotMaxTime);
  return { datesInRange, slotMinTime, slotMaxTime };
};

const setBlockersToTable = (
  workdays,
  holidays,
  datesInRange,
  selectedDate,
  cabinets,
  slotMinTime,
  slotMaxTime,
  schedulerAPI,
) => {
  schedulerAPI.getEvents().forEach((event) => event._def.ui.display === 'block' && event.remove());
  const blockers = getBlockers(workdays, holidays, datesInRange, selectedDate, cabinets, slotMinTime, slotMaxTime);
  schedulerAPI.addEventSource(blockers);
};

const setEventsToTable = (
  sessions,
  appointments,
  staffers,
  cabinets,
  filterStatuses,
  filterDoctors,
  patients,
  schedulerAPI,
) => {
  const sessionsToSet = mapScheduleDataToSessions(sessions, staffers, cabinets, true);
  const appointmentsToSet = mapAppointmentsDataToAppointments(
    appointments,
    filterStatuses,
    filterDoctors,
    cabinets,
    patients,
    staffers,
  );
  schedulerAPI.addEventSource([...appointmentsToSet, ...sessionsToSet]);
};

const setAppointmentsToTable = (
  appointments,
  cabinets,
  filterStatuses,
  filterDoctors,
  patients,
  staffers,
  schedulerAPI,
) => {
  const appointmentsToSet = mapAppointmentsDataToAppointments(
    appointments,
    filterStatuses,
    filterDoctors,
    cabinets,
    patients,
    staffers,
  );
  schedulerAPI.getEvents().forEach((event) => event._def.ui.display === null && event.remove());
  schedulerAPI.addEventSource(appointmentsToSet);
};

// Получает и передаёт все необходимые данные в таблицу
export const handleInitializeTableData = (data, tableFirstInit) => {
  const {
    filterCabinets,
    filterStatuses,
    filterDoctors,
    cabinets,
    staffers,
    sessions,
    patients,
    appointments,
    workdays,
    holidays,
    selectedDate,
    setNoDoctorsEvents,
    dispatch,
    schedulerAPI,
  } = data;

  // Вывод пустых значений в консоль. Очень пригодится чтобы понимать, с какими данными при инициализации есть проблемы
  logFalsyData(data, 'initializeTableData');

  // Обёртка для батчинга всех изменений (чтобы они разом заходили в таблицу)
  schedulerAPI.batchRendering(() => {
    // Выбранная дата
    const selectedDatePayload = selectedDate || new Date().setHours(0, 0, 0, 0);
    dispatch(setSelectedDate(selectedDatePayload));
    setTimeout(() => scrollTableToDate(selectedDatePayload, true));

    // Проброс в redux данных о сотрудниках
    dispatch(setStaffers(staffers));

    // Проброс в redux данных о пациентах
    dispatch(setPatients(patients));

    // Кабинеты на отображение в таблицу (они будут в ней отображаться из-за этого сета)
    schedulerAPI.setOption('resources', filterCabinets.length ? filterCabinets : cabinets);

    // Все доступные кабинеты (нигде не отображаются)
    dispatch(setInitialCabinets(cabinets));

    // Сет информации о выбранных докторах в таблицу
    schedulerAPI.setOption('dayPopoverFormat', filterDoctors);

    // Сессии и визиты
    !tableFirstInit &&
      setEventsToTable(
        sessions,
        appointments,
        staffers,
        cabinets,
        filterStatuses,
        filterDoctors,
        patients,
        schedulerAPI,
      );

    //Диапазон отображения
    const dateRangeToSet = setDateRangeToTable(
      filterDoctors,
      filterCabinets,
      cabinets,
      holidays,
      workdays,
      selectedDatePayload,
      schedulerAPI,
    );

    // Максимальное и минимальное рабочее время
    const { datesInRange, slotMinTime, slotMaxTime } = setMinMaxSlotTime(
      dateRangeToSet,
      selectedDatePayload,
      workdays,
      holidays,
      schedulerAPI,
    );

    // Высота таблицы
    schedulerAPI.setOption('contentHeight', getTableHeight(slotMinTime, slotMaxTime));

    // Ширина таблицы
    getShownColumnsCount().then((shownColumnsCount) => {
      const tableWidthToSet = 7 + 50 + 188 * shownColumnsCount;
      dispatch(setTableWidth(tableWidthToSet));
      setNoDoctorsEvents(!Boolean(shownColumnsCount));
    });

    // Блокеры
    setBlockersToTable(
      workdays,
      holidays,
      datesInRange,
      selectedDatePayload,
      cabinets,
      slotMinTime,
      slotMaxTime,
      schedulerAPI,
    );

    // Хэш-таблица колонок таблицы и приёмов врачей, для проверки необходимости отрисовывать колонки
    schedulerAPI.setOption('navLinks', getEventsHashmap([...appointments, ...sessions]));
  });
};

// Изменяет данные таблицы при изменении врачей в фильтрах
export const handleFilterDoctorsChange = (data) => {
  const {
    filterCabinets,
    filterDoctors,
    cabinets,
    workdays,
    holidays,
    selectedDate,
    setNoDoctorsEvents,
    dispatch,
    schedulerAPI,
  } = data;

  // Вывод пустых значений в консоль. Очень пригодится чтобы понимать, с какими данными при инициализации есть проблемы
  logFalsyData(data, 'handleFilterDoctorsChange');

  // Обёртка для батчинга всех изменений (чтобы они разом заходили в таблицу)
  schedulerAPI.batchRendering(function () {
    // Сет информации о выбранных докторах в таблицу
    schedulerAPI.setOption('dayPopoverFormat', filterDoctors);

    //Диапазон отображения
    const dateRangeToSet = setDateRangeToTable(
      filterDoctors,
      filterCabinets,
      cabinets,
      holidays,
      workdays,
      selectedDate,
      schedulerAPI,
    );

    // Максимальное и минимальное рабочее время
    const { datesInRange, slotMinTime, slotMaxTime } = setMinMaxSlotTime(
      dateRangeToSet,
      selectedDate,
      workdays,
      holidays,
      schedulerAPI,
    );

    // Высота таблицы
    schedulerAPI.setOption('contentHeight', getTableHeight(slotMinTime, slotMaxTime));

    // Блокеры
    setBlockersToTable(
      workdays,
      holidays,
      datesInRange,
      selectedDate,
      cabinets,
      slotMinTime,
      slotMaxTime,
      schedulerAPI,
    );

    // Ширина таблицы
    getShownColumnsCount().then((shownColumnsCount) => {
      const tableWidthToSet = 7 + 50 + 188 * shownColumnsCount;
      dispatch(setTableWidth(tableWidthToSet));
      setNoDoctorsEvents(!Boolean(shownColumnsCount));
    });
  });
};

// Изменяет данные таблицы при изменении кабинетов в фильтрах
export const handleFilterCabinetsChange = (data) => {
  const { filterCabinets, filterDoctors, cabinets, setNoDoctorsEvents, dispatch, schedulerAPI, workdays, holidays } =
    data;

  // Установка кабинетов в таблицу
  schedulerAPI.setOption('resources', filterCabinets.length ? filterCabinets : cabinets);

  // Установка инициализационного диапазона отображения (только если нет врачей в фильтре)
  if (!filterDoctors.length) {
    schedulerAPI.setOption('visibleRange', getInitialDateRange(filterCabinets, cabinets, workdays, holidays));
  }

  // Ширина таблицы
  getShownColumnsCount().then((shownColumnsCount) => {
    const tableWidthToSet = 7 + 50 + 188 * shownColumnsCount;
    dispatch(setTableWidth(tableWidthToSet));
    setNoDoctorsEvents(!Boolean(shownColumnsCount));
  });
};

// Изменяет данные таблицы при изменении статусов в фильтрах
export const handleFilterStatusChange = (data) => {
  const { filterStatuses, filterDoctors, cabinets, appointments, patients, staffers, schedulerAPI } = data;

  // TODO: не перерисовывать все визиты при применении фильтра статуса
  // Либо доставать значения из фильтра в каждом визите, либо писать тут логику поиска определённых визитов, с сетом в них пропа-маркера
  schedulerAPI.batchRendering(function () {
    setAppointmentsToTable(appointments, cabinets, filterStatuses, filterDoctors, patients, staffers, schedulerAPI);
  });
};

// Изменяет данные таблицы при изменении выбранной даты
export const handleSelectedDateChange = (data) => {
  const {
    filterCabinets,
    filterDoctors,
    relevantCabinets,
    workdays,
    holidays,
    selectedDate,
    dispatch,
    /*cabinets,*/ schedulerAPI,
  } = data;

  dispatch(setSelectedDate(selectedDate));
  setDateRangeToTable(filterDoctors, filterCabinets, relevantCabinets, holidays, workdays, selectedDate, schedulerAPI);

  if (selectedDate) {
    setTimeout(() => scrollTableToDate(selectedDate));
  }

  getShownColumnsCount().then((shownColumnsCount) => {
    const tableWidthToSet = 7 + 50 + 188 * shownColumnsCount;
    dispatch(setTableWidth(tableWidthToSet));
  });

  // const datesInRange = eachDayOfInterval({start: subDays(new Date(selectedDate), 1), end: addDays(new Date(selectedDate), 1)}).map((e) => e.getTime());
  // setBlockersToTable(workdays, holidays, datesInRange, selectedDate, cabinets, schedulerAPI.getOption('slotMinTime'), schedulerAPI.getOption('slotMaxTime'), schedulerAPI)
};

// Получает сообщение от WS и выясняет: нужно ли синхронизировать визиты таблицы с данными с бека?
// Если нужно - визит изменится/удалится/создастся
export const syncTableWithBackend = (msg, currentUserId, currentClinicId, setWsSessions, schedulerAPI) => {
  if (
    msg.data &&
    (!msg.data.length || !msg.data.every((appointment) => appointment.session_user_id === currentUserId))
  ) {
    setWsSessions((prev) => {
      // Массив сессий (действий юзера с визитами), которые были до этого изменения
      const previousSessions = prev;

      // Массив сессий (действий юзера с визитами), которые произошли сейчас
      const newSessions = msg.data;

      // Если в предыдущей сессии было БОЛЬШЕ визитов, надо понять, какая сессия исчёзла
      if (prev.length > msg.data.length) {
        // Найти сессию, которой больше нет
        const deletedSession = previousSessions.find(
          (prevSession) => !newSessions.some((newSession) => newSession.target_id === prevSession.target_id),
        );

        // Если она есть - найти визит с которым что-то случилось в API таблицы, убрать у него currentRedactor (ведь его никто больше не редактирует)
        if (deletedSession) {
          const deletedSessionAppointment = getFilteredEvents(
            schedulerAPI.getEvents(),
            'appointments',
            deletedSession.target_id,
          )[0];
          deletedSessionAppointment && deletedSessionAppointment.setExtendedProp('currentRedactor', '');

          // Получаем с бека исчезнувший визит
          const headers = { authorization: `Token ${sessionStorage.getItem('access_token')}` };
          axios
            .get(`${baseURL}/clinics/${currentClinicId}/appointments/${deletedSession.target_id}`, { headers })
            .then((res) => {
              if (res.status === 200) {
                const { id, patient, doctor, cancel_reason, comment, cabinet, starts_at, ends_at, status } = res.data;
                // Если на беке у визита есть cancel_reason - то он удалён. Его можно удалить и из API таблицы
                if (cancel_reason) {
                  deleteTableAppointment(id, schedulerAPI);
                  // Иначе - визит надо либо создать в таблице, либо обновить существующий
                } else {
                  axios.all([requests.clinic.get_patient(patient), requests.clinic.get_staff(currentClinicId)]).then(
                    axios.spread((patient, staff) => {
                      if (!getFilteredEvents(schedulerAPI.getEvents(), 'appointments', id)[0]) {
                        createTableAppointment(
                          {
                            ...res.data,
                            patient: patient.data,
                            doctor: staff.data.items.find((el) => el.id === doctor),
                          },
                          schedulerAPI,
                        );
                      } else {
                        updateTableAppointment(
                          id,
                          cabinet,
                          starts_at,
                          ends_at,
                          cancel_reason,
                          comment,
                          staff.data.items.find((el) => el.id === doctor),
                          status,
                          schedulerAPI,
                        );
                      }
                    }),
                  );
                }
              }
            })
            .catch((error) => {
              console.error('Не получилось получить данные для обновления изменившихся визитов', error);
            });
        }
        // Если в предыдущей сессии было МЕНЬШЕ визитов, надо понять, какая сессия появилась
      } else {
        // Определяю сессию, которая появилась
        const addedSession = newSessions.find(
          (newSession) => !previousSessions.some((prevSession) => prevSession.target_id === newSession.target_id),
        );

        // Если такая есть - надо найти визит в API таблицы и добавить редактора в этот визит
        if (addedSession) {
          const addedSessionAppointment = getFilteredEvents(
            schedulerAPI.getEvents(),
            'appointments',
            addedSession.target_id,
          )[0];
          if (addedSessionAppointment && !addedSessionAppointment._def.extendedProps.isCopied) {
            addedSessionAppointment.setExtendedProp('currentRedactor', addedSession.session_user_name);
          }
        }
      }
      return msg.data;
    });
  }
};

export const setNewBlockersIntoTable = (schedulerAPI, payload, slotMinTime, slotMaxTime, cabinets) => {
  const blockersToDelete = getFilteredEvents(
    schedulerAPI.getEvents(),
    'block',
    undefined,
    parse(payload.date, 'yyyy-MM-dd', new Date()).getTime(),
    addDays(parse(payload.date, 'yyyy-MM-dd', new Date()), 1).getTime(),
  );

  blockersToDelete.forEach((blocker) => blocker.remove());

  let blockersToCreate = [];

  const timezoneOffset = new Date().getTimezoneOffset() * 60000;

  const blockerSettings = {
    display: 'block',
    resourceIds: cabinets.map((c) => c.id),
    editable: false,
    startEditable: false,
    durationEditable: false,
    resourceEditable: false,
  };

  if (!payload.is_workday) {
    blockersToCreate = [
      {
        start: new Date(payload.date + `T${slotMinTime}.000Z`).getTime() + timezoneOffset,
        end: new Date(payload.date + `T${slotMaxTime}.000Z`).getTime() + timezoneOffset,
        ...blockerSettings,
      },
    ];
  } else {
    blockersToCreate = [
      {
        start: new Date(payload.date + `T${slotMinTime}.000Z`).getTime() + timezoneOffset,
        end: new Date(payload.date + `T${payload.time_start}.000Z`).getTime() + timezoneOffset,
        ...blockerSettings,
      },
      {
        start: new Date(payload.date + `T${payload.time_end}.000Z`).getTime() + timezoneOffset,
        end: new Date(payload.date + `T${slotMaxTime}.000Z`).getTime() + timezoneOffset,
        ...blockerSettings,
      },
    ];
  }

  blockersToCreate.forEach((blocker) => schedulerAPI.addEvent(blocker));
};
